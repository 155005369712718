.highlight .hll {
  background-color: #ffffcc;
}

.highlight .c {
  color: #aaaaaa;
  font-style: italic;
}

.highlight .err {
  color: #f00000;
  background-color: #f0a0a0;
}

.highlight .k {
  color: #0000aa;
}

.highlight .cm {
  color: #aaaaaa;
  font-style: italic;
}

.highlight .cp {
  color: #4c8317;
}

.highlight .c1 {
  color: #aaaaaa;
  font-style: italic;
}

.highlight .cs {
  color: #0000aa;
  font-style: italic;
}

.highlight .gd {
  color: #aa0000;
}

.highlight .ge {
  font-style: italic;
}

.highlight .gr {
  color: #aa0000;
}

.highlight .gh {
  color: #000080;
  font-weight: bold;
}

.highlight .gi {
  color: #00aa00;
}

.highlight .go {
  color: #888888;
}

.highlight .gp {
  color: #555555;
}

.highlight .gs {
  font-weight: bold;
}

.highlight .gu {
  color: #800080;
  font-weight: bold;
}

.highlight .gt {
  color: #aa0000;
}

.highlight .kc {
  color: #0000aa;
}

.highlight .kd {
  color: #0000aa;
}

.highlight .kn {
  color: #0000aa;
}

.highlight .kp {
  color: #0000aa;
}

.highlight .kr {
  color: #0000aa;
}

.highlight .kt {
  color: #00aaaa;
}

.highlight .m {
  color: #009999;
}

.highlight .s {
  color: #aa5500;
}

.highlight .na {
  color: #1e90ff;
}

.highlight .nb {
  color: #00aaaa;
}

.highlight .nc {
  color: #00aa00;
  text-decoration: underline;
}

.highlight .no {
  color: #aa0000;
}

.highlight .nd {
  color: #888888;
}

.highlight .ni {
  color: #800000;
  font-weight: bold;
}

.highlight .nf {
  color: #00aa00;
}

.highlight .nn {
  color: #00aaaa;
  text-decoration: underline;
}

.highlight .nt {
  color: #1e90ff;
  font-weight: bold;
}

.highlight .nv {
  color: #aa0000;
}

.highlight .ow {
  color: #0000aa;
}

.highlight .w {
  color: #bbbbbb;
}

.highlight .mf {
  color: #009999;
}

.highlight .mh {
  color: #009999;
}

.highlight .mi {
  color: #009999;
}

.highlight .mo {
  color: #009999;
}

.highlight .sb {
  color: #aa5500;
}

.highlight .sc {
  color: #aa5500;
}

.highlight .sd {
  color: #aa5500;
}

.highlight .s2 {
  color: #aa5500;
}

.highlight .se {
  color: #aa5500;
}

.highlight .sh {
  color: #aa5500;
}

.highlight .si {
  color: #aa5500;
}

.highlight .sx {
  color: #aa5500;
}

.highlight .sr {
  color: #009999;
}

.highlight .s1 {
  color: #aa5500;
}

.highlight .ss {
  color: #0000aa;
}

.highlight .bp {
  color: #00aaaa;
}

.highlight .vc {
  color: #aa0000;
}

.highlight .vg {
  color: #aa0000;
}

.highlight .vi {
  color: #aa0000;
}

.highlight .il {
  color: #009999;
}

@media (prefers-color-scheme: dark) {
  .highlight pre {
    background-color: #272822;
  }

  .highlight .hll {
    background-color: #272822;
  }

  .highlight .c {
    color: #75715e;
  }

  .highlight .err {
    color: #960050;
    background-color: #1e0010;
  }

  .highlight .k {
    color: #66d9ef;
  }

  .highlight .l {
    color: #ae81ff;
  }

  .highlight .n {
    color: #f8f8f2;
  }

  .highlight .o {
    color: #f92672;
  }

  .highlight .p {
    color: #f8f8f2;
  }

  .highlight .cm {
    color: #75715e;
  }

  .highlight .cp {
    color: #75715e;
  }

  .highlight .c1 {
    color: #75715e;
  }

  .highlight .cs {
    color: #75715e;
  }

  .highlight .ge {
    font-style: italic;
  }

  .highlight .gs {
    font-weight: bold;
  }

  .highlight .kc {
    color: #66d9ef;
  }

  .highlight .kd {
    color: #66d9ef;
  }

  .highlight .kn {
    color: #f92672;
  }

  .highlight .kp {
    color: #66d9ef;
  }

  .highlight .kr {
    color: #66d9ef;
  }

  .highlight .kt {
    color: #66d9ef;
  }

  .highlight .ld {
    color: #e6db74;
  }

  .highlight .m {
    color: #ae81ff;
  }

  .highlight .s {
    color: #e6db74;
  }

  .highlight .na {
    color: #a6e22e;
  }

  .highlight .nb {
    color: #f8f8f2;
  }

  .highlight .nc {
    color: #a6e22e;
  }

  .highlight .no {
    color: #66d9ef;
  }

  .highlight .nd {
    color: #a6e22e;
  }

  .highlight .ni {
    color: #f8f8f2;
  }

  .highlight .ne {
    color: #a6e22e;
  }

  .highlight .nf {
    color: #a6e22e;
  }

  .highlight .nl {
    color: #f8f8f2;
  }

  .highlight .nn {
    color: #f8f8f2;
  }

  .highlight .nx {
    color: #a6e22e;
  }

  .highlight .py {
    color: #f8f8f2;
  }

  .highlight .nt {
    color: #f92672;
  }

  .highlight .nv {
    color: #f8f8f2;
  }

  .highlight .ow {
    color: #f92672;
  }

  .highlight .w {
    color: #f8f8f2;
  }

  .highlight .mf {
    color: #ae81ff;
  }

  .highlight .mh {
    color: #ae81ff;
  }

  .highlight .mi {
    color: #ae81ff;
  }

  .highlight .mo {
    color: #ae81ff;
  }

  .highlight .sb {
    color: #e6db74;
  }

  .highlight .sc {
    color: #e6db74;
  }

  .highlight .sd {
    color: #e6db74;
  }

  .highlight .s2 {
    color: #e6db74;
  }

  .highlight .se {
    color: #ae81ff;
  }

  .highlight .sh {
    color: #e6db74;
  }

  .highlight .si {
    color: #e6db74;
  }

  .highlight .sx {
    color: #e6db74;
  }

  .highlight .sr {
    color: #e6db74;
  }

  .highlight .s1 {
    color: #e6db74;
  }

  .highlight .ss {
    color: #e6db74;
  }

  .highlight .bp {
    color: #f8f8f2;
  }

  .highlight .vc {
    color: #f8f8f2;
  }

  .highlight .vg {
    color: #f8f8f2;
  }

  .highlight .vi {
    color: #f8f8f2;
  }

  .highlight .il {
    color: #ae81ff;
  }

  .highlight .gh {
  }

  .highlight .gu {
    color: #75715e;
  }

  .highlight .gd {
    color: #f92672;
  }

  .highlight .gi {
    color: #a6e22e;
  }
}
